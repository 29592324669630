<template>
  <v-card color="#fff">
    <v-card-title class="body-1">
      <v-col cols="10">
        <h5><v-icon color="primary" class="mt-n2 mx-auto">{{this.icon}}</v-icon>
        {{title}}</h5>
      </v-col>
      <v-col cols="2" class="text-right">
        <ButtonIcon :id="this.id" />
      </v-col>
    </v-card-title>
    <v-card-text>
      <ChartPie :servicePath="chartServicePath" :initialData="initialData" :id="id" />
    </v-card-text>
    <v-card-actions>
      <router-link
        v-if="linkTo && linkTo!='popup'"
        :to="linkTo"
      >{{$vuetify.lang.t('$vuetify.navigations.detailedview')}}</router-link>
    </v-card-actions>
  </v-card>
</template>

<script>
import ChartPie from "@/components/shared/charts/ChartPie.vue";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
export default {
  components: {
    ChartPie,
    ButtonIcon
  },
  data: function() {
    return {
      chartServicePath: this.servicePath,
      chartData: this.initialData,
      detailsShow: false
    };
  },
  props: ["title", "servicePath", "initialData", "linkTo", "id", "icon"]
};
</script>   