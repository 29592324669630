<template>
  <div>
    <GChart
      type="PieChart"
      :data="initialData"
      :options="chartOptions"
      :events="chartEvents"
      @ready="onChartReady"
    />
  </div>
</template>
<script>
import { GChart } from "vue-google-charts";
export default {
  components: {
    GChart
  },
  props: ["initialData", "id"],
  data() {
    return {
      chartEvents: {
        ready: () => {
          document.getElementById(
            "download_" + this.id
          ).href = this.chart.getImageURI();
        }
      },
      chartOptions: {
        animation: { startup: true, duration: 1000 },
        chartArea: {
          left: 20,
          top: 20,
          bottom: 20,
          width: "100%",
          height: "100%"
        },
        colors: ['#9dbb68', '#f26334', '#002C6C', '#FA8072', '#CCCCFF', '#5F9EA0', '#00A36C', '#EADDCA', '#A52A2A', '#6F4E37', '#808000'],
        responsiveAnimationDuration: 0
      }
    };
  },
  methods: {
    onChartReady(chart) {
      this.chart = chart;
    }
  }
};
</script>