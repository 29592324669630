<template>
  <v-tooltip left>
    <template v-slot:activator="{ on }">
      <a :id="'download_'+id" href="/" download v-on="on">
        <v-icon class="mt-n1">mdi-cloud-download</v-icon>
      </a>
    </template>
    <span>{{$vuetify.lang.t('$vuetify.tooltips.ButtonIcon')}}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: ["id"]
};
</script>   
        