<template>
  <v-card color="#fff" height="500px">
    <v-card-title class="body-1">
      <v-col cols="10">
        <h5><v-icon color="primary" class="mt-n2 mx-auto">mdi-trending-up</v-icon>
        {{title}}</h5>
      </v-col>
      <v-col cols="2" class="text-right">
        <ButtonIcon :id="this.id"/>
      </v-col>
    </v-card-title>
    <v-card-text>
      <ChartCurve :initialData="initialData" :id="this.id" :lineColors="lineColors"/>
    </v-card-text>
  </v-card>
</template>

<script>
import ChartCurve from "@/components/shared/charts/ChartCurve";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
export default {
  components: {
    ChartCurve,
    ButtonIcon
  },
  props: ["title", "initialData", "linkTo", "id", "icon", "lineColors"]
};
</script>   