<template>
  <div>
    <GChart
      type="LineChart"
      :data="initialData"
      :options="chartOptions"
      :events="chartEvents"
      @ready="onChartReady"
    />
  </div>
</template>
<script>
import { GChart } from "vue-google-charts";
export default {
  components: {
    GChart
  },
  data: function() {
    return {
      chartEvents: {
        ready: () => {
          document.getElementById(
            "download_" + this.id
          ).href = this.chart.getImageURI();
        }
      },
      chartOptions: {
        height: 350,
        animation: { startup: true, duration: 1000 },
        explorer: {
          axis: "horizontal",
          actions: ["dragToPan", "dragToZoom", "rightClickToReset"]
        },
        hAxis: {
          allowContainerBoundaryTextCutoff: true,
          logScale: true,
          format: ' ',
        },
        vAxis: {
          minValue: 0
        },
        focusTarget: "category",
        chartArea: { width: "80%", height: "80%" },
        legend: { position: "top", alignment: "end", format: ' ' },
        pointsVisible: true,
        colors: this.lineColors == 'solution' ? ['RED','#002C6C'] : ['#002C6C', '#F26334'],
        responsiveAnimationDuration: 0
      }
    };
  },
  watch: {
    lineColors: function(v) {
      console.log(v)
    }
  },
  props: ["initialData", "id", "lineColors"],
  methods: {
    onChartReady(chart) {
      this.chart = chart;
    }
  }
};
</script>