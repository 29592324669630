<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <Table
          :servicePath="'product/list'"
          :headers="productHeaders"
          :title="$vuetify.lang.t('$vuetify.cards.pending_approval_product')"
          icon="mdi-cart"
          tableType="product-review"
          :showAddProduct="showEditProduct"
          v-on:add-product="dialogEditProduct = true"
          v-on:remove-product="dialogremoveProduct = true"
          :reload="reload"
          :hideFilters="true"
          :itemsPerPageSent=5
          :noMinHeight="true"
        />
      </v-col>
      <v-col cols="12" md="6">
        <Table
          :servicePath="'company/list'"
          :headers="companyHeaders"
          :title="$vuetify.lang.t('$vuetify.cards.pending_approval_company')"
          icon="mdi-briefcase"
          tableType="company-review"
          :showAddProduct="showAddProduct"
          v-on:add-product="dialogAddProduct = true"
          :reload="reload"
          :hideFilters="true"
          :itemsPerPageSent=5
          :noMinHeight="true"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <ChartPieCard
          v-if="pie1Translated"
          id="pie1"
          icon="mdi-briefcase"
          :title="$vuetify.lang.t('$vuetify.navigations.companys')"
          :initialData="pie1Translated"
          :linkTo="'/manager/companys'"
        />
      </v-col>
      <v-col cols="12" md="6">
        <IncomeCard :data="income"/>
      </v-col>
      <v-col cols="12" md="6">
        <ChartCurveCard
          id="chart1"
          :title="$vuetify.lang.t('$vuetify.charts.yearly_company_registation_dynamics')"
          :initialData="yearlyCounts(1)"
        />
      </v-col>
      <v-col cols="12" md="6">
        <ChartCurveCard
          id="chart3"
          :title="$vuetify.lang.t('$vuetify.charts.yearly_membership_payments_dynamics')"
          :initialData="yearlyCounts(4)"
        />
      </v-col>
      <v-col cols="12" md="6">
        <ChartCurveCard
          id="chart2"
          :title="$vuetify.lang.t('$vuetify.charts.yearly_product_registation_dynamics')"
          :initialData="yearlyCounts(2)"
          lineColors="solution"
        />
      </v-col>
      <v-col cols="12" md="6">
        <ChartCurveCard
          id="chart4"
          :title="$vuetify.lang.t('$vuetify.charts.yearly_barcode_payments_dynamics')"
          :initialData="yearlyCounts(5)"
          lineColors="solution"
        />
      </v-col>
      <v-col cols="12" md="6">
        <ChartPieCard
          v-if="productionFieldsTranslated"
          id="pie2"
          icon="mdi-cart"
          :title="$vuetify.lang.t('$vuetify.company.production_fields')"
          :initialData="productionFieldsTranslated"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Api from "@/services/api";
import ChartPieCard from "@/components/shared/cards/ChartPieCard";
import IncomeCard from "@/components/manager/cards/IncomeCard";
import ChartCurveCard from "@/components/shared/cards/ChartCurveCard";
import Table from "@/components/shared/Table";
export default {
  components: {
    ChartPieCard,
    IncomeCard,
    ChartCurveCard,
    Table,
  },
  data: function() {
    return {
      pie1: [["a", "b"]],
      productionFields: [["a", "b"]],
      yearlyData: [["a", "b", "c", "d"][(0, 0, 0, 0)]],
      income: false,
      reload: 0,
      showAddProduct: false,
      showEditProduct: false,
      dialogEditProduct: false,
      productData: false,
    };
  },
  methods: {
    reloadTable() {
      this.reload += 1;
    },
    yearlyCounts(index) {
      var obj = new Array();
      var t = this;
      this.yearlyData.forEach(function(item) {
        if(item[0] == 'year') { 
          item[0] = t.$i18n.messages[t.$i18n.locale]['$vuetify']['charts']['year']
        }

        if(item[index] == 'company' || item[index] == 'product' || item[index] == 'income_membership' || item[index] == 'income_barcode') {
          item[index] = t.$i18n.messages[t.$i18n.locale]['$vuetify']['charts'][item[index]];
        }

        obj.push([item[0],item[index]]);
      });
      return obj;
    }
  },
  computed: {
    pie1Translated() {
      var obj = new Array();
      obj.push(['type','count']);
      for (var i = 0; i < this.pie1.length; i++) {
        var idx = this.pie1[i];
        var c = 0

        if(idx[0] == 'active') {
          c = idx[1] + this.pie1[3][1];
          idx[0] = this.$i18n.messages[this.$i18n.locale]['$vuetify']['charts'][idx[0]]+' - '+c+"\r"+this.$i18n.messages[this.$i18n.locale]['$vuetify']['charts']['has_debt']+' - '+this.pie1[3][1];
          obj.push([this.pie1[i][0],c]);
        } else if(idx[0] == 'excluded') {
          idx[0] = this.$i18n.messages[this.$i18n.locale]['$vuetify']['charts'][idx[0]]+' - '+idx[1];
          obj.push([this.pie1[i][0],this.pie1[i][1]]);
        }
      }
      
      return obj;
    },
    productionFieldsTranslated() {
      var obj = new Array();
      obj[0] = this.productionFields[0];
      for (var i = 1; i < this.productionFields.length; i++) {
        obj[i] = JSON.parse(JSON.stringify(this.productionFields[i]));
        obj[i][0] = this.$i18n.messages[this.$i18n.locale]["$vuetify"]["production_fields"][obj[i][0]]+' - '+obj[i][1];
      }
      return obj;
    },
    productHeaders() {
      return [
        {
          text: this.$vuetify.lang.t("$vuetify.tables.company"),
          value: "company.name",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.product.brand_name"),
          value: "brand",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.product.name"),
          value: "name",
        },
        {
          text: "",
          value: "editProduct",
          sortable: false,
        },
        {
          text: "",
          value: "removeProduct",
          sortable: false,
        },
      ];
    },
    companyHeaders() {
      return [
        {
          text: this.$vuetify.lang.t("$vuetify.tables.name"),
          value: "name",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.tables.id_code"),
          value: "id_code",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.tables.prefix"),
          value: "prefix",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.tables.reg_date"),
          value: "reg_date",
        },
        {
          text: "",
          value: "removeCompany",
          sortable: false,
        },
      ];
    },
  },
  beforeCreate() {
    Api.dashboard().then(({ data }) => {
      this.pie1 = data.companys;
      this.productionFields = data.production_fields;
      this.yearlyData = data.yearly;
      this.income = [data.income_barcode,data.income_membrship]
    });
  },
};
</script>
