<template>
  <div class="row  ma-0 pa-0">
    <v-card class="cols col-md-6 ma-0 pa-0 mb-5">
      <v-card-title>
        <v-icon color="primary" class="mr-2">mdi-cash</v-icon>
        <h6>GS1 {{$i18n.messages[$i18n.locale]['$vuetify']['charts']['income']}}</h6>
        <hr>
      </v-card-title >
      <v-card-text>
        <v-list>
          <v-list-item v-for="(item, idx) in data[1]" :key="item.idx">
            <v-list-item-content>
              <v-list-item-title
                v-text="$i18n.messages[$i18n.locale]['$vuetify']['charts'][idx]"
              ></v-list-item-title>
            </v-list-item-content>
              {{ item }}
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <v-card class="cols col-md-6 ma-0 pa-0 mb-5">
      <v-card-title>
        <v-icon color="primary" class="mr-2">mdi-cash</v-icon>
        <h6>GS1 Solutions {{$i18n.messages[$i18n.locale]['$vuetify']['charts']['income']}}</h6>
      </v-card-title >
      <v-card-text>
        <v-list>
          <v-list-item v-for="(item, idx) in data[0]" :key="item.idx">
            <v-list-item-content>
              {{$i18n.messages[$i18n.locale]['$vuetify']['charts'][idx]}}
            </v-list-item-content>

            <v-list-item-action>
              {{ item }}
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data: function() {
    return {};
  },
  props: ["data"],
};
</script>
